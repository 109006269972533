import React from 'react';
import styled from 'styled-components';
import media from '../utils/media';
import axios from 'axios';
import qs from 'qs';
import ReCAPTCHA from 'react-google-recaptcha';
import FlexRow from '../utils/flex-row';
import Formsy from 'formsy-react';
import FormsyText from './formsy/formsy-text';
import FormsyTextarea from './formsy/formsy-textarea';
import FormsyCheckbox from './formsy/formsy-checkbox';
import InputContainer from './input-container';
import { Button } from './button';

const Form = styled(FlexRow)`
  align-items: flex-end;
`;

const Message = styled.div`
  width: 100%;

  ${media.desk`
    width: 66.6666%;
    order: 1;
  `}
`;

const OneThirdColumn = styled.div`
  width: 100%;

  ${media.desk`
    width: 33.3333%;
  `}
`;

const Name = styled(OneThirdColumn)`
  ${media.desk`
    order: 3;
  `}
`;

const Email = styled(OneThirdColumn)`
  ${media.desk`
    order: 4;
  `}
`;

const Newsletter = styled(OneThirdColumn)`
  margin-bottom: 9px;

  ${media.desk`
    order: 2;
    margin-bottom: 0;
  `}
`;

const Submit = styled(OneThirdColumn)`
  position: relative;

  ${media.desk`
    order: 5;
  `}
`;

const SubmitFeedback = styled.div.attrs({ className: 'small' })`
  position: absolute;
  top: ${props => props.theme.buttonHeightPixels + 7}px;
  left: 0;
  width: 100%;
  padding-right: ${props => props.theme.columnGapPixelsDesk}px;

  .success {
    color: ${props => props.theme.formAccentColor};
  }

  .error {
    color: ${props => props.theme.formErrorTextColor};
  }
`;

const NotHere = styled.div`
  order: 99;
  height: 0px;
  visibility: collapse;
  padding: 0;
`;

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
    this.onCaptchaSubmit = this.onCaptchaSubmit.bind(this);

    this.formName = 'contact';
    this.submitUrl = '/contact-submit';
    this.submitLabel = 'submit';
    this.captchaRef = React.createRef();

    this.errorMessages = {
      isEmail: 'email required',
      isDefaultRequiredValue: 'required',
    };

    this.buttonLabelTimeout = 2000;

    this.state = {
      canSubmit: false,
      submitButtonLabel: this.submitLabel,
      submitButtonClassName: '',
      feedback: null,
    };
  }

  onValid() {
    this.setState({ canSubmit: true });
  }

  onInvalid() {
    this.setState({ canSubmit: false });
  }

  onValidSubmit(formData, resetForm, invalidateForm) {
    this.formData = formData;
    this.resetForm = resetForm;
    this.invalidateForm = invalidateForm;

    this.captchaRef.current.execute();
    // this.onCaptchaSubmit();
  }

  onCaptchaSubmit() {
    const formData = this.formData;
    const resetForm = this.resetForm;
    // const invalidateForm = this.invalidateForm;

    this.setState({
      submitButtonLabel: 'sending...',
      submitButtonClassName: 'executing',
      feedback: null,
    });

    formData['form-name'] = this.formName;
    formData['g-recaptcha-response'] = this.captchaRef.current.getValue();

    axios
      .post(
        this.submitUrl,
        qs.stringify(formData))
      .then(response => {
        console.log(response);
        if (response.status / 100 === 2) {
          // Clear form and indicate success
          this.setState({
            submitButtonLabel: 'received',
            feedback: (
              <span className="success">
                we'll be in touch!
              </span>
            ),
          });

          this.props.onSubmit();
          setTimeout(() => {
            resetForm();
            this.captchaRef.current.reset();
            this.setState({
              submitButtonLabel: this.submitLabel,
              submitButtonClassName: '',
            });
          }, this.buttonLabelTimeout);
        } else {
          // Indicate error
          this.setState({
            submitButtonLabel: this.submitLabel,
            submitButtonClassName: '',
            feedback: (
              <span className="error">{`not sent: ${response.message}`}</span>
            ),
          });
        }
      })
      .catch(error => {
        // Indicate error
        console.log(error);
        this.setState({
          submitButtonLabel: this.submitLabel,
          submitButtonClassName: '',
          feedback: (
            <span className="error">{`not sent: ${
              error.message
            }`}</span>
          ),
        });
      });
  }

  render() {
    const submitDisabled = !this.props.allowSubmit || !this.state.canSubmit;

    return (
      <Form
        as={Formsy}
        onValidSubmit={this.onValidSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
        noValidate={true}
        name={this.formName}
        action={this.submitUrl}
        data-netlify="true"
        data-netlify-recaptcha="true"
        netlify-honeypot="buzz"
      >
        <Message as={InputContainer} >
          <FormsyTextarea
            label="what do you need?"
            name="message"
            rows="4"
            required={true}
            validationErrors={this.errorMessages}
          />
        </Message>
        <Name as={InputContainer}>
          <FormsyText
            label="first name:"
            type="text"
            name="name"
            value=""
            required={true}
            validationErrors={this.errorMessages}
          />
        </Name>
        <Email  as={InputContainer} >
          <FormsyText
            label="email:"
            type="email"
            name="email"
            value=""
            validations="isEmail"
            required={true}
            validationErrors={this.errorMessages}
          />
        </Email>
        <Newsletter  as={InputContainer}>
          <FormsyCheckbox
            label="get the newsletter:"
            name="newsletter"
            value={true} />
        </Newsletter>
        <Submit>
          <Button type="submit" className="accent" disabled={submitDisabled}>
            {this.state.submitButtonLabel}
          </Button>
          <SubmitFeedback>
          {this.state.feedback}
          </SubmitFeedback>

          <ReCAPTCHA
            sitekey="6LdanH4UAAAAAM0f3cRYw1dcOfCI5tpDBBj-L4hP"
            ref={this.captchaRef}
            onChange={this.onCaptchaSubmit}
            size="invisible">
          </ReCAPTCHA>

        </Submit>

        <NotHere>
          <FormsyText
              label="not for humans:"
              type="text"
              name="buzz"
              value=""
            />
        </NotHere>
      </Form>
    );
  }
}

ContactForm.defaultProps = {
  allowSubmit: true,
  onSubmit: () => {},
};

export default ContactForm;
